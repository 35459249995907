@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:400,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Belleza&display=swap');
@import url('https://fonts.googleapis.com/css?family=Perpetua:400,700&display=swap');

@font-face {
    font-family: 'Galliard';
    src: url('./assets/fonts/Galliard/Galliard Roman/Galliard Roman.otf') format('otf');
    font-weight: 400;
    font-style: normal;
}