@tailwind base;
@tailwind components;
@tailwind utilities;

::selection {
    background-color: #000000;
    color: #FFFFFF;
}

/* If dark mode is enabled, we set ::selection to be inverse of the default. */
@media (prefers-color-scheme: dark) {
    ::selection {
        background-color: #FFFFFF;
        color: #000000;
    }
}

.custom-link {
    text-decoration: underline;
    text-decoration-color: #000;
    @media (prefers-color-scheme: dark) {
        text-decoration-color: #FFF;
    }
    text-underline-offset: 2px;
    text-decoration-thickness: 2px;
}
  
.custom-link:hover {
    text-decoration: underline;
    text-underline-offset:2px;
    text-decoration-thickness: 1px;
}

.custom-link-alt {
    text-decoration: underline;
    text-decoration-color: #000;
    @media (prefers-color-scheme: dark) {
        text-decoration-color: #FFF;
    }
    text-underline-offset: 1px;
    text-decoration-thickness: 1px;
}
  
.custom-link-alt:hover {
    text-decoration: underline;
    text-underline-offset:1px;
    text-decoration-thickness: 0px;
}